.input-label {
    color: black;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.focused {
    color: #1976d2 !important;
}

.label-error {
    color: #d32f2f !important;
}