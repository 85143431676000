.Profile-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.Profile-Info {
    display: flex;
    flex-direction: column;
}

.Profile-Info-name {
    font-size: 14px;
    font-weight: 600;
}

.Profile-Info-role {
    font-size: 12px;
    font-weight: 500;
}

.header-logo {
    width: 72px;
}

@media(max-width: 720px){
    .header-logo {
        width: 40px;
    }

    .header-school-name {
        font-size: 20px !important;
    }

    .Profile-Info {
        display: none;
    }

    .MuiBox-root {
        padding: 10px !important;
    }
  }