
.login-form {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forgit-link {
  text-decoration: none;
  color: #1976d2;
  margin-top: -10px;
}