.contactus-wrapper {
    padding: 3rem;
}

.tc-grid-container {
    padding: 5rem;
    text-align: center;
}

@media(max-width: 720px){
    .contactus-wrapper {
        padding: 0.5rem;
    }

    .tc-grid-container {
        padding: 0.5rem;
    }
}